<template>
  <div>
    <div class="tab-buttons">
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        @click="changeTab(index)"
        :class="{ active: activeTab === index }"
      >
        {{ $t(tab.name) }}
      </button>
    </div>
    <div class="tab-content">
      <slot :tab="tabs[activeTab]"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    changeTab(index) {
      this.activeTab = index;
    },
  },
};
</script>

<style lang="scss" scoped>
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-extra-large: 1200px;
.tab-buttons {
  display: flex;
  border-bottom: 1px solid gray;
  padding-left: 4.5rem;
}

.tab-buttons button {
  cursor: pointer;
  border: none;
  background: none;
  margin: 0;
  padding: 8px 16px;
  font-size: 16px;
  color: white;
}

.tab-buttons button.active {
  border-bottom: 2px solid #ffffff;
}

.tab-content {
  width: 90%;
  margin: 1rem 5rem;
}

@media (max-width: $breakpoint-small) {
  .tab-buttons {
    padding-left: 1.5rem;
  }
  .tab-content {
    width: 90%;
    margin: 1rem 2rem;
  }
}
</style>
